import React, { useEffect, useState } from "react";
import { Image, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../user.css';
import { logout } from '../slices/authSlice';
import { useLogoutMutation } from '../slices/usersApiSlice';

const LeftSideMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeLink, setActiveLink] = useState('');
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add("sidenav");
    setActiveLink(location.pathname); // Set the active link based on current route
  }, [location]);

  const closeBtn = () => {
    document.body.classList.toggle("closesidenav");
    document.body.classList.remove("sidenav");
  };

  const handleLogout = () => {
    dispatch(logout()); // This will clear localStorage and Redux state
    navigate('/login');
  };

  const isActive = location.pathname === '/appearance' || location.pathname.startsWith('/edit');
  const isActiveProject = location.pathname === '/projects' || location.pathname.startsWith('/create');

  const [menuActive, setMenuActive] = useState(false);

  const handleSideMenu = () => {
    setMenuActive(prevState => !prevState); 
  }

  return (
    <>
      <div className={`leftsidemenu ${menuActive ? 'active' : ''}`} id="mySidenav">
        <div className="sidenavflex">
          <div className="sidenavtop">
            <div className="brand">
              <Link to='#'>LandingFast 🚀</Link>
            </div>
            {/* <Link className="menuprofilebg" to='#'>
            <div className="table-content menuprofile">
              <span><Image src="/images/v.webp" className="menuimg" alt="profile" /></span>
              <div>
                <h6 className="h6">Vasanth</h6>
                <p>Frontend Developer</p>
              </div>
            </div>
          </Link> */}
            <Link
              to='/dashboard'
              className={activeLink === '/dashboard' ? 'active' : ''}
              onClick={() => setActiveLink('/dashboard')}
            >
              <span className="me-1"><Image src={`${process.env.PUBLIC_URL}/images/dashboard.svg`} alt="dashboard" className="menuicons" /></span> Dashboard
            </Link>
            <Link
              to='/projects'
              className={isActiveProject ? 'active' : ''}
              onClick={() => setActiveLink('/projects')}
            >
              <span className="me-1"><Image src={`${process.env.PUBLIC_URL}/images/Appearence.svg`} alt="Projects" className="menuicons" /></span> Projects
            </Link>
            <Link
              to='/my-projects'
              className={activeLink === '/my-projects' ? 'active' : ''}
              onClick={() => setActiveLink('/my-projects')}
            >
              <span className="me-1"><Image src={`${process.env.PUBLIC_URL}/images/Blog.svg`} alt="Blog" className="menuicons" /></span> My Projects
            </Link>
            {/* <Link
            to='/appearance'
            className={isActive ? 'active' : ''}
            onClick={() => setActiveLink('/appearance')}
          >
           <span className="me-1"><Image src={`${process.env.PUBLIC_URL}/images/Appearence.svg`} alt="Appearence" className="menuicons"/></span> Appearance
          </Link> */}
            <Link
              to='/blog'
              className={activeLink === '/blog' ? 'active' : ''}
              onClick={() => setActiveLink('/blog')}
            >
              <span className="me-1"><Image src={`${process.env.PUBLIC_URL}/images/Blog.svg`} alt="Blog" className="menuicons" /></span> Blog
            </Link>
            <Link
              to='/settings'
              className={activeLink === '/settings' ? 'active' : ''}
              onClick={() => setActiveLink('/settings')}
            >
              <span className="me-1"><Image src={`${process.env.PUBLIC_URL}/images/Settings.svg`} alt="Settings" className="menuicons" /></span> Settings
            </Link>
          </div>
          <div className="sidenavend">
            <Link onClick={handleLogout}>Logout</Link>
          </div>
        </div>
      </div>

      <div className={`opendash ${menuActive ? 'active' : ''}`} onClick={handleSideMenu}>
        <Image src="/images/dashopen.svg" className="opendashicon" alt="opendashboard" />
      </div>
    </>
  );
};

export default LeftSideMenu;
