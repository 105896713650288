import React, { useState, useEffect } from 'react';
import LeftSideMenu from '../components/LeftSideMenu';
import UserNav from '../components/UserNav';
import { Link } from 'react-router-dom';
import { Button, Image, Modal, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { config } from '../config/indexConfig';

import Loader from '../components/Loader';

const Projects = () => {

    const [loading, setLoading] = useState(false)
    const [pageLoad, setPageLoad] = useState(true)
    const [createModalshow, setCreateModalShow] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [isProjectNameValid, setIsProjectNameValid] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const navigate = useNavigate()

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPageLoad(false)
        }, 1000);
        return () => clearTimeout(timeout)
    }, [])

    const handleCloseModal = () => setCreateModalShow(false);
    const handleShowModal = () => setCreateModalShow(true);

    const handleNext = () => {
        if (projectName.trim() && isProjectNameValid) {
            navigate('/create-project', { state: { projectName } });
        } else if (!projectName.trim()) {
            toast.error('Project name field cannot be empty!');
        } else if (!isProjectNameValid) {
            toast.error('Project name already exists. Please choose another.');
        }
    };


    const checkProjectNameAvailability = async (name) => {
        try {
            setLoading(true);
            setIsChecked(false);
            const response = await axios.get(`${config.api.baseUrl}project/${name}`)
            setIsProjectNameValid(false);
        } catch (error) {
            if (error.response?.status === 404) {
                // If the project does not exist, it's valid
                setIsProjectNameValid(true);
                setIsChecked(true);
            } else {
                console.error('Error checking project name:', error);
                toast.error('An error occurred while validating the project name.');
            }
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (debounceTimeout) clearTimeout(debounceTimeout);
        if (projectName.trim()) {
            const timeout = setTimeout(() => {
                checkProjectNameAvailability(projectName);
            }, 1000); // Adjust debounce delay as needed
            setDebounceTimeout(timeout);
        } else {
            setIsProjectNameValid(true); // Reset validity if input is cleared
            setIsChecked(false);
        }
    }, [projectName]);

    if (pageLoad) {
        return (
            <Loader />
        )
    }

    return (
        <>
            {loading ? <Loader /> :
                <div className='pagecontent'>
                    <LeftSideMenu />
                    <div className='pagecontentbox'>
                        <div className='pagebannerflex'>
                            <div className="pagetitle col-lg-6">
                                <h2 className="pagetitleh2">Personalize Your Developer Portfolio</h2>
                                <p className='content userpagecontent'>Welcome to your profile editor! Customize your portfolio by updating your information below. Your details will be displayed on your portfolio page, giving visitors a snapshot of your skills, experience, and personality. Make sure to save changes to keep your portfolio current and engaging!</p>
                            </div>
                            <div className='pagebannerimgbg'>
                                <Image src={`${process.env.PUBLIC_URL}/images/homedash1.png`} className='bannerimg' alt='bannerimg' />
                            </div>
                        </div>
                    </div>
                    <div className='pagecontentbox'>
                        <div className='createbanner'>
                            <div className="pagetitle col-lg-6">
                                <h2 className="pagetitleh2">Start Building Your Dream Website!</h2>
                                <p className='content userpagecontent'>Turn your ideas into reality with LandingFast!. No coding required.</p>
                            </div>
                            <div className='pagebannerimgbg'>
                                <Button onClick={handleShowModal} className='btn sitebtn userbtn'>+ Create A New Website</Button><br />
                                <small>Get Started In Less Than A Minute!</small>
                            </div>
                        </div>
                    </div>
                </div>}
            <Modal centered show={createModalshow} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Project Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={projectName}
                                onChange={(e) => setProjectName(e.target.value)}
                                placeholder="devfolio"
                                autoFocus
                                isInvalid={!isProjectNameValid}
                            />
                            <Form.Control.Feedback type="invalid">
                                Project name is already taken. Please choose another.
                            </Form.Control.Feedback>
                            <small>Project name is used as the identifier of the website.</small>
                            <br />
                            {isChecked && isProjectNameValid && (
                                <Form.Text className="text-success">
                                    Yay! Project name is available!
                                </Form.Text>
                            )}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button
                        className="btn sitebtn userbtn customizebtn"
                        disabled={
                            loading ||
                            !isProjectNameValid ||
                            !isChecked ||
                            !projectName.trim()
                        }
                        onClick={handleNext}
                    >
                        {loading ? 'Checking...' : 'Get Started'}
                    </Button>

                </Modal.Footer>
            </Modal>

            <ToastContainer />
        </>
    )
}

export default Projects